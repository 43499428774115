import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import BeforeAfterSlider from "../../components/react-before-after-slider";
import locales from "../../constants";

const YorkSchoolOfEnglish = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  const before = require("../../assets/img/portfolio/york_laptop_old.png");
  const after = require("../../assets/img/portfolio/york_laptop_new.png");
  return (
    <Layout
      seo={{
        title: "York School of English",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #B91914, #CC1B15, #B91914, #CC1B15, #B91914 )",
        icons: "#a7110c",
        navClass: "york",
        ogImage: require("../../assets/img/portfolio/york_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/york-school-of-english/",
      }}
    >
      <PortfolioHeader name="york" height="260" />
      <section className="container-fluid york_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                York <br /> School of English
              </h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Ze szkołą York rozpoczęliśmy współpracę w 2016 roku. Klient
                zgłosił się do nas z potrzebą realizacji nowej strony
                internetowej na bazie aktualnej, która w jego opinii była mało
                intuicyjna i nieużyteczna dla użytkowników. Naszym zadaniem było
                pomóc szkole, aby jej aktualni i przyszli uczniowie mogli w
                łatwy, przyjazny i zrozumiały sposób zapoznać się z pełnym
                obszarem działalności szkoły. Naszym pierwszym i podstawowym
                zadaniem było przetworzenie pełnej mapy strony, która w swoich
                licznych zagnieżdżeniach zawierała ponad 70 podstron. Sami
                spójrzcie jak wiele kategorii i podstron znajdowało się na
                stronie
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid york_section_laptop">
        <img
          className="img-fluid img_non_mobile"
          src={require("../../assets/img/portfolio/york_laptop.png")}
          alt=""
        />
        <div className="york_section_laptop-wrapper">
          <BeforeAfterSlider
            before={before}
            after={after}
            width={957}
            height={599}
          />
        </div>
        <img
          className="img-fluid img_mobile"
          src={require("../../assets/img/portfolio/york_laptop_mobile.png")}
          alt=""
        />
      </section>

      <section className="container-fluid york_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_1.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Strona www</h3>
              <p>
                W pierwszej fazie skupiliśmy się na tym, które strony są
                niezbędne, które możemy połączyć, a które są nieaktualne i
                wymagają usunięcia, bądź przeformatowania w inne. Z analizy
                ruchu wynikało, że aktualna strona posiada wysoki współczynnik
                odrzuceń – to sugerowało, że użytkownicy czują się zagubieni i
                nie wiedzą gdzie znajdą informacje, które są im niezbędne –
                jednocześnie spędzali dość dużo czasu na niej co z kolei
                pokazywało, że próbują tam odszukać ważne z ich perspektywy
                informacje. Dzięki takiej analizie wiedzieliśmy też jakie strony
                są dla Klientów najważniejsze, ile czasu na niej spędzają i w
                jaki sposób poszukują informacji.
              </p>
              <p>
                Po wstępnym “odchudzeniu” strony powstała makieta, która
                skupiała się na trzech obszarach: poprawa użyteczności,
                zmniejszenie liczby zagnieżdżeń oraz dostęp do najistotniejszych
                informacji z poziomu strony głównej. Makietę konsultowaliśmy z
                Klientem, aby mieć potwierdzenie części naszych wniosków ze
                specyfiką biznesu i charakterystyką grupy docelowej. To
                szczególnie ważny etap współpracy. Po akceptacji makiety, która
                w strukturze strony pozwoliła nam zredukować liczbę podstron o
                ponad 30% nie tracąc przy tym żadnych istotnych informacji
                przystąpiliśmy do etapu graficznego. Tutaj naszym głównym
                zadaniem była z kolei redukcja ilości treści oraz przedstawienie
                dużej części w formie graficznej oraz hasłowej – miało to
                zapewnić użytkownikowi szybki sposób na znalezienie tych
                informacji, których faktycznie szuka, jednocześnie w sposób
                nienachalny informując o innych obszarach działalności.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-6">
            <div className="inner">
              <p>
                Po akceptacji makiety, która w strukturze strony pozwoliła nam
                zredukować liczbę podstron o ponad 30% nie tracąc przy tym
                żadnych istotnych informacji przystąpiliśmy do etapu
                graficznego. Tutaj naszym głównym zadaniem była z kolei redukcja
                ilości treści oraz przedstawienie dużej części w formie
                graficznej oraz hasłowej – miało to zapewnić użytkownikowi
                szybki sposób na znalezienie tych informacji, których faktycznie
                szuka, jednocześnie w sposób nienachalny informując o innych
                obszarach działalności.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_2.jpg")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-6 offset-md-3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_3.jpg")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_4">
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_4.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-5 offset-md-3">
            <div className="inner">
              <h3>Marketing internetowy</h3>
              <p>
                When przeprowadził dwie duże kampanie rekrutacyjne mające na
                celu pozyskanie uczestników szkoleń na rok szkolny 2016/2017
                oraz 2017/2018. Dodatkowym celem było zachęcanie użytkowników do
                rozwiązywania testów klasyfikacyjnych do grup poziomowych.
                Kampanie były intensywnie prowadzone w okresie od sierpnia do
                października 2016 roku oraz w tym samym czasie w 2017 roku.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_5">
          <div className="col-md-6">
            <div className="inner">
              <p>
                Kampanie rekrutacyjne były prowadzone w Google AdWords oraz
                Facebook Ads – oddzielnie dla każdej z grup docelowych. Grupy
                docelowe, do których kierowaliśmy komunikaty to: osoby dorosłe,
                studenci, młodzież i dzieci. Reklamy kampanii dedykowanych
                poszczególnym grupom odsyłały użytkowników do podstron z
                odpowiednimi ofertami kursów. Dodatkowo działania reklamowe były
                wzmocnione reklamami remarketingowymi, docierającymi ponownie do
                użytkowników, którzy już wcześniej zainteresowali się ofertą.
                Kampania była kierowana w promieniu 20 km od siedziby firmy, na
                osoby które mieszkają w tym rejonie, lub często w nim
                przebywają. Klient był zadowolony z efektów. Źródła Facebook Ads
                i Google AdWords wygenerowały przeważającą liczbę sesji wobec
                źródła organicznego, wejść bezpośrednich czy pozostałych (np.
                linki odsyłające). Odnotowano kilkadziesiąt konwersji
                zdefiniowanych jako przejście do zakładki z testami, sprawdzenie
                cenników kursów, czy sprawdzenie form kontaktów ze szkołą.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_5.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_6">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/york_main_6.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Projekty graficzne</h3>
              <p>
                Do sukcesu kampanii przyczyniły się także projekty graficzne
                banerów wykonane przez Studio Leon. Zadbaliśmy o to, aby były
                spójne wizualnie z nową stroną internetową, dzięki temu nie
                dezorientowały użytkowników, powodując płynne i intuicyjne
                przejście do stron docelowych kampanii. Studio Leon
                zaprojektowało zarówno banery internetowe, jak i outdoorowe.
              </p>
              <p>Spółki z Grupy ADream zaangażowane w projekt</p>
              <ul className="list_companies">
                <li>
                  <a
                    href="https://zensite.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/agency/zensite_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://when.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/agency/when_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://studioleon.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../assets/img/agency/studio_leon_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default YorkSchoolOfEnglish;
